import { INavData } from '@coreui/angular';

let childRoutes = [];
// add views accrding to role and permissions.
const permissionData = sessionStorage.getItem('permissionData');
if (permissionData && permissionData.toString() != '0') {
  const permissionDataJson = JSON.parse(permissionData);
  const modulesAndPermissions = permissionDataJson.modulesAndPermissions;
  let permissionArr = [];
  modulesAndPermissions.map((ele) => {
    ele.permissions.map((e) => {
      if (e.itemName === 'View' && e.checked)
        permissionArr.push(ele.module);
    })
  })

  childRoutes.push({
    name: 'Dashboard',
    url: '/',
    icon: 'icon-speedometer',
  }, {
    title: true,
    name: 'Modules'
  },)
  if (permissionArr.includes('Manage User')) {
    childRoutes.push({
      name: 'User',
      url: 'user',
      icon: 'icon-user'
    });
  }
  if (permissionArr.includes('Manage Driver')) {
    childRoutes.push( {
      name: 'Driver',
      url: 'driver',
      icon: 'icon-user'
    });
  }
  if (permissionArr.includes('Manage Package')) {
    childRoutes.push({
      name: 'Package',
      url: 'package',
      icon: 'icon-social-dropbox'
    });
  }
  if (permissionArr.includes('Manage Vehicle')) {
    childRoutes.push({
      name: 'Vehicle',
      url: 'vehicle',
      icon: 'cil-car-alt'
    });
  }
  if (permissionArr.includes('Manage Role')) {
    childRoutes.push({
      name: 'Role',
      url: 'role',
      icon: 'cil-lan'
    });
  }
  if (permissionArr.includes('Manage Staff')) {
    childRoutes.push({
      name: 'Staff',
      url: 'staff',
      icon: ' cil-group'
    });
  }
  if (permissionArr.includes('Manage Payout')) {
    childRoutes.push({
      name: 'Payout',
      url: 'payout',
      icon: ' cil-group'
    });
  }
  if (permissionArr.includes('Manage Refund Request')) {
    childRoutes.push({
      name: 'Refund Request',
      url: 'refund',
      icon: ' cil-group'
    });
  }
  if (permissionArr.includes('Manage Order')) {
    childRoutes.push({
      name: 'Order',
      url: 'order',
      icon: ' cil-group'
    });
  }
  if (permissionArr.includes('Manage User Privacy')) {
    childRoutes.push({
      name: 'Privacy Policy',
      url: 'user-privacy',
      icon: 'cil-group'
    });
  }
  if (permissionArr.includes('Manage User Term Condition')) {
    childRoutes.push({
      name: 'Term Condition',
      url: 'user-term-condition',
      icon: 'cil-group'
    });
  }
  if (permissionArr.includes('Manage Driver Privacy')) {
    childRoutes.push({
      name: 'Driver Policy',
      url: 'driver-privacy',
      icon: 'cil-group'
    });
  }
  if (permissionArr.includes('Manage Driver Term Condition')) {
    childRoutes.push({
      name: 'Term Condition',
      url: 'driver-term-condition',
      icon: 'cil-group'
    });
  }
  if (permissionArr.includes('Manage Partner Agreement')) {
    childRoutes.push({
      name: 'Partner Agreement',
      url: 'partner-agreement',
      icon: 'cil-group'
    });
  }
  if (permissionArr.includes('Manage Prohibited Restricted')) {
    childRoutes.push({
      name: 'Prohibited & Restricted Item Policy',
      url: 'prohibited-restricted',
      icon: 'cil-group'
    });
  }
} else {
  childRoutes = [
    {
      name: 'Dashboard',
      url: '/',
      icon: 'icon-speedometer',
    },
    {
      title: true,
      name: 'Modules'
    },
    {
      name: 'User',
      url: 'user',
      icon: 'icon-user'
    },
    {
      name: 'Driver',
      url: 'driver',
      icon: 'icon-user'
    },
    {
      name: 'Package',
      url: 'package',
      icon: 'icon-social-dropbox'
    },
    {
      name: 'Vehicle',
      url: 'vehicle',
      icon: 'cil-car-alt'
    },
    {
      name: 'Role',
      url: 'role',
      icon: 'cil-lan'
    },
    {
      name: 'Staff',
      url: 'staff',
      icon: ' cil-group'
    },
    {
      name: 'Payout',
      url: 'payout',
      icon: ' cil-group'
    },
    {
      name: 'Refund Request',
      url: 'refund',
      icon: ' cil-group'
    },
    {
      name: 'Order',
      url: 'order',
      icon: ' cil-group'
    },
    // {
    //   name: 'User - Privacy Policy',
    //   url: 'user-privacy',
    //   icon: 'cil-group'
    // },
    // {
    //   name: 'User - Term Condition',
    //   url: 'user-term-condition',
    //   icon: 'cil-group'
    // },
    // {
    //   name: 'Driver - Privacy Policy',
    //   url: 'driver-privacy',
    //   icon: 'cil-group'
    // },
    // {
    //   name: 'Driver - Term Condition',
    //   url: 'driver-term-condition',
    //   icon: 'cil-group'
    // },
    // {
    //   name: 'Partner Agreement',
    //   url: 'partner-agreement',
    //   icon: 'cil-group'
    // },
    // {
    //   name: 'Prohibited & Restricted Item Policy',
    //   url: 'prohibited-restricted',
    //   icon: 'cil-group'
    // },
  ]
}


export const navItems: INavData[] = childRoutes;





  // {
  //   name: 'Colors',
  //   url: '/theme/colors',
  //   icon: 'icon-drop'
  // },
  // {
  //   name: 'Typography',
  //   url: '/theme/typography',
  //   icon: 'icon-pencil'
  // },
  // {
  //   title: true,
  //   name: 'Components'
  // },
  // {
  //   name: 'Base',
  //   url: '/base',
  //   icon: 'icon-puzzle',
  //   children: [
  //     {
  //       name: 'Cards',
  //       url: '/base/cards',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Carousels',
  //       url: '/base/carousels',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Collapses',
  //       url: '/base/collapses',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Forms',
  //       url: '/base/forms',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Navbars',
  //       url: '/base/navbars',
  //       icon: 'icon-puzzle'

  //     },
  //     {
  //       name: 'Pagination',
  //       url: '/base/paginations',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Popovers',
  //       url: '/base/popovers',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Progress',
  //       url: '/base/progress',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Switches',
  //       url: '/base/switches',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Tables',
  //       url: '/base/tables',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Tabs',
  //       url: '/base/tabs',
  //       icon: 'icon-puzzle'
  //     },
  //     {
  //       name: 'Tooltips',
  //       url: '/base/tooltips',
  //       icon: 'icon-puzzle'
  //     }
  //   ]
  // },
  // {
  //   name: 'Buttons',
  //   url: '/buttons',
  //   icon: 'icon-cursor',
  //   children: [
  //     {
  //       name: 'Buttons',
  //       url: '/buttons/buttons',
  //       icon: 'icon-cursor'
  //     },
  //     {
  //       name: 'Dropdowns',
  //       url: '/buttons/dropdowns',
  //       icon: 'icon-cursor'
  //     },
  //     {
  //       name: 'Brand Buttons',
  //       url: '/buttons/brand-buttons',
  //       icon: 'icon-cursor'
  //     }
  //   ]
  // },
  // {
  //   name: 'Charts',
  //   url: '/charts',
  //   icon: 'icon-pie-chart'
  // },
  // {
  //   name: 'Icons',
  //   url: '/icons',
  //   icon: 'icon-star',
  //   children: [
  //     {
  //       name: 'CoreUI Icons',
  //       url: '/icons/coreui-icons',
  //       icon: 'icon-star',
  //       badge: {
  //         variant: 'success',
  //         text: 'NEW'
  //       }
  //     },
  //     {
  //       name: 'Flags',
  //       url: '/icons/flags',
  //       icon: 'icon-star'
  //     },
  //     {
  //       name: 'Font Awesome',
  //       url: '/icons/font-awesome',
  //       icon: 'icon-star',
  //       badge: {
  //         variant: 'secondary',
  //         text: '4.7'
  //       }
  //     },
  //     {
  //       name: 'Simple Line Icons',
  //       url: '/icons/simple-line-icons',
  //       icon: 'icon-star'
  //     }
  //   ]
  // },
  // {
  //   name: 'Notifications',
  //   url: '/notifications',
  //   icon: 'icon-bell',
  //   children: [
  //     {
  //       name: 'Alerts',
  //       url: '/notifications/alerts',
  //       icon: 'icon-bell'
  //     },
  //     {
  //       name: 'Badges',
  //       url: '/notifications/badges',
  //       icon: 'icon-bell'
  //     },
  //     {
  //       name: 'Modals',
  //       url: '/notifications/modals',
  //       icon: 'icon-bell'
  //     }
  //   ]
  // },
  // {
  //   name: 'Widgets',
  //   url: '/widgets',
  //   icon: 'icon-calculator',
  //   badge: {
  //     variant: 'info',
  //     text: 'NEW'
  //   }
  // },
  // {
  //   divider: true
  // },
  // {
  //   title: true,
  //   name: 'Extras',
  // },
  // {
  //   name: 'Pages',
  //   url: '/pages',
  //   icon: 'icon-star',
  //   children: [
  //     {
  //       name: 'Login',
  //       url: '/login',
  //       icon: 'icon-star'
  //     },
  //     {
  //       name: 'Register',
  //       url: '/register',
  //       icon: 'icon-star'
  //     },
  //     {
  //       name: 'Error 404',
  //       url: '/404',
  //       icon: 'icon-star'
  //     },
  //     {
  //       name: 'Error 500',
  //       url: '/500',
  //       icon: 'icon-star'
  //     }
  //   ]
  // },
  // {
  //   name: 'Disabled',
  //   url: '/dashboard',
  //   icon: 'icon-ban',
  //   badge: {
  //     variant: 'secondary',
  //     text: 'NEW'
  //   },
  //   attributes: { disabled: true },
  // },
  // {
  //   name: 'Download CoreUI',
  //   url: 'http://coreui.io/angular/',
  //   icon: 'icon-cloud-download',
  //   class: 'mt-auto',
  //   variant: 'success',
  //   attributes: { target: '_blank', rel: 'noopener' }
  // },
  // {
  //   name: 'Try CoreUI PRO',
  //   url: 'http://coreui.io/pro/angular/',
  //   icon: 'icon-layers',
  //   variant: 'danger',
  //   attributes: { target: '_blank', rel: 'noopener' }
  // }
