import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ComponentRef, EventEmitter, Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { Overlay, OverlayConfig, OverlayRef } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import { DefaultLayoutAddComponent } from './default-layout-add/default-layout-add.component';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DefaultService {
  userId: any;
  public url: string = `${environment.baseUrl}admin`;
  public authToken = sessionStorage.getItem("auth_token");
  
  public httpOptions = {
    headers: new HttpHeaders({
      Authorization: this.authToken,
      'user_type': '1'
    }),
  };

  constructor(private http: HttpClient, public overlay?: Overlay) { }

  // public url: string = `${environment.baseUrl}users`

  /* component reference */
  public componentRef: ComponentRef<DefaultLayoutAddComponent>;

  /* overlay reference */
  public overlayRef: OverlayRef;

  /* overlay configuration */
  public overlayConfig(data: any, isview): ComponentRef<DefaultLayoutAddComponent> {

    console.log("metg=od 2", data);
    const overlayConfig: OverlayConfig = new OverlayConfig();

    overlayConfig.positionStrategy = this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically();

    overlayConfig.hasBackdrop = true;

    this.overlayRef = this.overlay.create(overlayConfig);
    const portal: ComponentPortal<DefaultLayoutAddComponent> =
      new ComponentPortal<DefaultLayoutAddComponent>(DefaultLayoutAddComponent);
    this.componentRef = this.overlayRef.attach(portal);
    this.componentRef.instance.userId = data.id;
    this.componentRef.instance.isViewOnly = isview;
    this.overlayRef.backdropClick().subscribe(() => this.overlayRef.detach());
    return this.componentRef;
  }

  /** close overlay */
  public closeOverlay() {
    this.overlayRef.detach();
    this.componentRef.destroy();

  }

  public changeProfilePic(data, id){
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.authToken,
        'user_type': '1',
      }),
    };

    console.log("defaultService",data);
    console.log("changePicApiCalled===>", this.http.post(`${this.url}/update-admin-profile/${id}`, data, httpOptions));
    
    return this.http.post(`${this.url}/update-admin-profile/${id}`, data, httpOptions);

  }

  public getAdminUserbyId(id)
: Observable<any> {
    return this.http.get(
      this.url + "/get-admin-user-details/" + id,
      this.httpOptions
    );
  }

}
