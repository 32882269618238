import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UserService } from '../user.service';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-user-order-update',
  templateUrl: './user-order-update.component.html',
  styleUrls: ['./user-order-update.component.scss']
})
export class UserOrderUpdateComponent implements OnInit {
  isViewOnly: boolean = false;
  public userId: any;
  public userdate;
  public orderUpdateForm: UntypedFormGroup;
  isEditable: boolean = false;
  public overallRating: number;

  config = {
    format: "DD-MM-YYYY",
    drops: 'right',
  }

  constructor(private userService: UserService, private datePipe: DatePipe) {

    this.orderUpdateForm = new UntypedFormGroup({
      order_id: new UntypedFormControl("", [Validators.required]),

      driver_name: new UntypedFormControl("", [
        Validators.required,
        // Validators.pattern("^[a-zA-Z]+$"),
      ]),
      customer_name: new UntypedFormControl("", [
        Validators.required,
        // Validators.pattern("^[a-zA-Z]+$"),
      ]),
      pickup_location: new UntypedFormControl("", [
        // Validators.required
      ]),
      dropoff_location: new UntypedFormControl("", [
        // Validators.required
      ]),
      phone_number: new UntypedFormControl("", [
        Validators.required,
        //Validators.pattern("P[0-9]*6"),
      ]),
      dateTimeDelivery: new UntypedFormControl("", []),

      amount: new UntypedFormControl("", []),
      orderStatus: new UntypedFormControl("", []),
      rating: new UntypedFormControl("", []),
    });
  }
  

  ngOnInit(): void {
    console.log("working");
    console.log("this.urId",this.userId);
    if (this.userId) {
      this.userService.getUserOrderById(this.userId).subscribe((usr: any) => {
        console.log("usr.userDetails", usr.orderDetails);
        var deliveryDateAndTime = this.datePipe.transform(usr.orderDetails.deliveryDateAndTime,'dd-MM-yyyy hh:mm:ss');

        this.userdate = usr.orderDetails;
        this.orderUpdateForm.patchValue({
          order_id: usr.orderDetails.orderId,
          driver_name: usr.orderDetails.driver_id.first_name + ' '+ usr.orderDetails.driver_id.last_name,
          customer_name: usr.orderDetails.user_id.first_name + ' '+ usr.orderDetails.user_id.last_name,
          dateTimeDelivery: deliveryDateAndTime
        });
         this.isEditable = true;
      });

      // this.userService.getUserRatingById(this.userId).subscribe((usr: any) => {
      //   // this.overallRating = parseFloat(usr.customerData[0].overallRating.$numberDecimal);
      // });
    }
  }

  title = 'google-places-autocomplete';
  userAddress: string = ''
  userLatitude: string = ''
  userLongitude: string = ''


  handleAddressChange(address: any) {
    console.log('Called .....');
    this.userAddress = address.formatted_address
    this.userLatitude = address.geometry.location.lat()
    this.userLongitude = address.geometry.location.lng()
  }

  closeForm() {
    this.userService.closeOrderUpdateOverlay();
  }

}