import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from './login.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent implements OnInit {
  iLogin: Ilogin = new Ilogin();
  public loginForm: UntypedFormGroup;
  public ErrorMsg: String;
  public passClass = 'fa-eye-slash';
  constructor(private LoginService: LoginService, private router: Router) {

    this.loginForm = new UntypedFormGroup({
      user_name: new UntypedFormControl("", [
        Validators.required
      ]),
      password: new UntypedFormControl("", [
        Validators.required
      ]),
    });
    this.ErrorMsg = "";
  }


  ngOnInit() {
    if (sessionStorage.getItem("isLogin") !== null) {
      this.router.navigateByUrl('/default/dashboard');
    }
  }

  //***ADMIN LOGIN LOGIC***
  submitted = false;

  onForgotPass()
  {
    this.router.navigateByUrl('forgot-password');

  }

  onShowHidePass() {
    if (this.passClass === 'fa-eye-slash') {
      this.passClass = 'fa-eye';
    } else {
      this.passClass = 'fa-eye-slash';
    }
  }
  onLogin() {
    this.submitted = true;
    if (this.loginForm.valid) {

      this.LoginService.Login(this.iLogin.UserName, this.iLogin.Password).subscribe(
        data => {
          console.log("Login => ");
          console.log(data.data);

          sessionStorage.setItem("id", data.data._id)
          sessionStorage.setItem("loginUserName", data.data.first_name);
          sessionStorage.setItem("loginUserPic", data.data.profile_pic);
          console.log("loginUserPic===>",data.data.profile_pic);
          
          sessionStorage.setItem("lastName", data.data.last_name);
          sessionStorage.setItem("isLogin", "true");

          sessionStorage.setItem("isLogin", "true");
          sessionStorage.setItem("auth_token", data.data.auth_token);
          sessionStorage.setItem("permissionData", JSON.stringify(data.data.permissionData));
          this.router.navigateByUrl('/default/dashboard', { state: {'page': 'login'} });
        },
        erro => {
          this.ErrorMsg = erro.error.message
        });


      this.submitted = false;
    } else {
      Object.keys(this.loginForm.controls).forEach(key => {
        const controlErrors: ValidationErrors = this.loginForm.get(key).errors;
        if (controlErrors != null) {
          Object.keys(controlErrors).forEach(keyError => {
            console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
          });
        }
      });
    }

    







    // console.log(this.iLogin.UserName)
    // if(this.iLogin.UserName == 'admin' && this.iLogin.Password == 'admin')
    // {
    //   this.router.navigateByUrl('/default/dashboard');
    //   sessionStorage.setItem("isLogin", "true");
    // }
    // else
    // {
    //   this.router.navigateByUrl('login');
    // }
  }
}

export class Ilogin {
  UserName: string;
  Password: string;
}
