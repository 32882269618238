import { Component } from '@angular/core';

@Component({
  templateUrl: 'switches.component.html'
})
export class SwitchesComponent {

  constructor() { }

}
