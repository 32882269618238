import { Component } from '@angular/core';

@Component({
  templateUrl: 'tables.component.html'
})
export class TablesComponent {

  constructor() { }

}
