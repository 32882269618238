import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';
import { FormsComponent } from './views/base/forms.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { ForgotPasswordComponent } from './views/forgot-password/forgot-password.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { SetNewPasswordComponent } from './views/set-new-password/set-new-password.component';
import { VerifyOTPComponent } from './views/verify-otp/verify-otp.component';

let childRoutes = [];
// add views accrding to role and permissions.

const permissionData = sessionStorage.getItem('permissionData');

// if permissionData is 0 then it is admin otherwise it is staff role.

if (permissionData && permissionData.toString() != '0') {
  const permissionDataJson = JSON.parse(permissionData);
  const modulesAndPermissions = permissionDataJson.modulesAndPermissions;
  let permissionArr = [];
  modulesAndPermissions.map((ele) => {
    ele.permissions.map((e) => {
      if (e.itemName === 'View' && e.checked)
        permissionArr.push(ele.module);
    })
  })
  console.log("☢️ ~ file: app.routing.ts ~ line 30 ~ modulesAndPermissions.map ~ permissionArr", permissionArr)
  childRoutes.push({
    path: 'dashboard',
    loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
  })
  if (permissionArr.includes('Manage User')) {
    childRoutes.push({
      path: 'user',
      loadChildren: () => import('./user/user.module').then(m => m.UserModule)
    });
  }
  if (permissionArr.includes('Manage Driver')) {
    childRoutes.push({
      path: 'driver',
      loadChildren: () => import('./driver/driver.module').then(m => m.DriverModule)
    });
  }
  if (permissionArr.includes('Manage Package')) {
    childRoutes.push({
      path: 'package',
      loadChildren: () => import('./package/package.module').then(m => m.PackageModule)
    });
  }
  if (permissionArr.includes('Manage Vehicle')) {
    childRoutes.push({
      path: 'vehicle',
      loadChildren: () => import('./vehicle/vehicle.module').then(m => m.VehicleModule)
    });
  }
  if (permissionArr.includes('Manage Role')) {
    childRoutes.push({
      path: 'role',
      loadChildren: () => import('./staffrole/staffrole.module').then(m => m.StaffroleModule)
    });
  }
  if (permissionArr.includes('Manage Staff')) {
    childRoutes.push({
      path: 'staff',
      loadChildren: () => import('./staff/staff.module').then(m => m.StaffModule)
    });
  }
 if (permissionArr.includes('Manage Payout')) {
    childRoutes.push({
      path: 'payout',
      loadChildren: () => import('./payout/payout.module').then(m => m.PayoutModule)
    });
  }
  if (permissionArr.includes('Manage Refund Request')) {
    childRoutes.push({
      path: 'refund',
      loadChildren: () => import('./refund/refund.module').then(m => m.RefundModule)
    });
  }
  if (permissionArr.includes('Manage Order')) {
    childRoutes.push({
      path: 'order',
      loadChildren: () => import('./order/order.module').then(m => m.OrderModule)
    });
  }
  if (permissionArr.includes('Manage User Privacy')) {
    childRoutes.push({
      path: 'user-privacy',
      loadChildren: () => import('./user-privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule)
    });
  }

  if (permissionArr.includes('Manage Driver Privacy')) {
    childRoutes.push({
      path: 'driver-privacy',
      loadChildren: () => import('./driver-privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule)
    });
  }
  if (permissionArr.includes('User Term Condition')) {
    childRoutes.push({
      path: 'user-term-condition',
      loadChildren: () => import('./user-term-condition/term-condition.module').then(m => m.TermConditionModule)
    });
  }
  if (permissionArr.includes('Driver Term Condition')) {
    childRoutes.push({
      path: 'driver-term-condition',
      loadChildren: () => import('./driver-term-condition/term-condition.module').then(m => m.TermConditionModule)
    });
  }
  if (permissionArr.includes('Partner Agreement')) {
    childRoutes.push({
      path: 'partner-agreement',
      loadChildren: () => import('./partner-agreement/partner-agreement.module').then(m => m.PartnerAgreementModule)
    });
  }
  if (permissionArr.includes('Prohibited Restricted')) {
    childRoutes.push({
      path: 'prohibited-restricted',
      loadChildren: () => import('./prohibited-restricted/prohibited-restricted.module').then(m => m.ProhibitedRestrictedModule)
    });
  }

} else {
  childRoutes = [
    {
      path: 'dashboard',
      loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
    },
    {
      path: 'base',
      loadChildren: () => import('./views/base/base.module').then(m => m.BaseModule)
    },
    {
      path: 'user',
      loadChildren: () => import('./user/user.module').then(m => m.UserModule)
    },
    {
      path: 'driver',
      loadChildren: () => import('./driver/driver.module').then(m => m.DriverModule)
    },
    {
      path: 'package',
      loadChildren: () => import('./package/package.module').then(m => m.PackageModule)
    },
    {
      path: 'vehicle',
      loadChildren: () => import('./vehicle/vehicle.module').then(m => m.VehicleModule)
    },
    {
      path: 'role',
      loadChildren: () => import('./staffrole/staffrole.module').then(m => m.StaffroleModule)
    },
    {
      path: 'staff',
      loadChildren: () => import('./staff/staff.module').then(m => m.StaffModule)
    },
    {
      path: 'payout',
      loadChildren: () => import('./payout/payout.module').then(m => m.PayoutModule)
    },
    {
      path: 'refund',
      loadChildren: () => import('./refund/refund.module').then(m => m.RefundModule)
    },
    {
      path: 'order',
      loadChildren: () => import('./order/order.module').then(m => m.OrderModule)
    },
    {
      path: 'user-privacy',
      loadChildren: () => import('./user-privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule)
    },
    {
      path: 'user-term-condition',
      loadChildren: () => import('./user-term-condition/term-condition.module').then(m => m.TermConditionModule)
    },
    {
      path: 'driver-privacy',
      loadChildren: () => import('./driver-privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule)
    },
    {
      path: 'driver-term-condition',
      loadChildren: () => import('./driver-term-condition/term-condition.module').then(m => m.TermConditionModule)
    },
    {
      path: 'partner-agreement',
      loadChildren: () => import('./partner-agreement/partner-agreement.module').then(m => m.PartnerAgreementModule)
    },
    {
      path: 'prohibited-restricted',
      loadChildren: () => import('./prohibited-restricted/prohibited-restricted.module').then(m => m.ProhibitedRestrictedModule)
    }
  ]
}

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'forms',
    component: FormsComponent,
  },

  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    data: {
      title: 'Forgot Password'
    }
  },
  {
    path: 'verify-otp/:type/:id',
    component: VerifyOTPComponent,
    data: {
      title: 'Verify OTP'
    }
  },
  {
    path: 'set-new-password/:reset_password_token',
    component: SetNewPasswordComponent,
    data: {
      title: 'Set new password'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },
  {
    path: 'default',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: childRoutes
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
