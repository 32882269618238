import { Component, OnInit, ViewChild } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import { SimpleModalService } from 'ngx-simple-modal';
import { ConfirmComponent } from "../../../views/confirm/confirm.component";
import { DefaultService } from "../default.service";
import { HttpClient } from "@angular/common/http";
import { UserService } from "../../../user/user.service";
import { Router } from '@angular/router';

@Component({
  selector: "app-default-layout-add",
  templateUrl: "./default-layout-add.component.html",
  styleUrls: ["./default-layout-add.component.scss"],
})


export class DefaultLayoutAddComponent implements OnInit {
  userId: any;
  isViewOnly = false;
  public imagePath: String;
  public selectedFiles: [];
  public previews: [];

  public profileUpdateForm: UntypedFormGroup;


  constructor(private toastr: ToastrService, private userService: UserService, private http: HttpClient, private defaultService: DefaultService, private router: Router) {

    this.profileUpdateForm = new UntypedFormGroup({
      firstName: new UntypedFormControl(""),
      lastName: new UntypedFormControl(""),
      role: new UntypedFormControl(""),
      profile_pic: new UntypedFormControl("")
    });
  }

  ngOnInit(): void {
    if (this.userId) {
      this.defaultService.getAdminUserbyId(this.userId).subscribe((usr: any) => {

        this.profileUpdateForm.patchValue({
          firstName: usr.userDetails.first_name,
          lastName: usr.userDetails.last_name,
          role: usr.userDetails?.roleId?.roleName,
        });
        this.imagePath = usr.userDetails.profile_pic;
        console.log("this.imagePath==>", this.imagePath);
        // this.isEditable = true;
      });
    }
  }

  uploadFile(event) {
    const file: any = (event.target as HTMLInputElement).files[0];
    console.log("file==>", file);

    var allowedExtensions =
      /(\image\/jpg|\image\/jpeg|\image\/png|\image\/gif)$/i;
    if (!allowedExtensions.exec(file.type)) {
      this.toastr.error('Invalid Image file type');
      this.profileUpdateForm.patchValue({
        profile_pic: ''
      });
      this.imagePath = ''
      return false;
    }
    this.profileUpdateForm.patchValue({
      profile_pic: file,
    });

    this.selectedFiles = event.target.files;

    this.previews = [];
    if (this.selectedFiles && this.selectedFiles) {
      const numberOfFiles = this.selectedFiles.length;
      console.log("numberOfFiles===>", numberOfFiles);
      for (let i = 0; i < numberOfFiles; i++) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.imagePath = e.target.result;
        };
        reader.readAsDataURL(this.selectedFiles[i]);
      }
    }
  }

  closeModal() {
    this.defaultService.closeOverlay();
  }

  // submitted = false;
  onSubmitUser() {
    // if (this.profileUpdateForm.valid) {
    //   const profileData = this.profileUpdateForm.value;

    //   var formData: any = new FormData();

    //   if (typeof profileData.profile_pic !== 'string') {
    //     formData.append("profile_pic", profileData.profile_pic);
    //   }
    //   console.log("formData==>", formData.profile_pic);
    //   this.defaultService.changeProfilePic(formData, this.userId).subscribe((data) => {
    //     this.defaultService.closeOverlay();
    //   });

    //    // save current route first
    //    const currentRoute = '/default/dashboard';
    //    console.log("currentRoute==>", currentRoute);
    //    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
    //      this.router.navigate([currentRoute]); // navigate to same route
    //    }); 
    // }
    if (this.profileUpdateForm.valid) {
      const profileData = this.profileUpdateForm.value;

      var formData: any = new FormData();
      if (typeof profileData.profile_pic !== 'string') {
        formData.append("profile_pic", profileData.profile_pic);
      }
      console.log("formData==>", formData.profile_pic);
      this.defaultService.changeProfilePic(formData, this.userId).subscribe((data) => {
        console.log("changeProfilePic===>",data);                
        this.defaultService.closeOverlay();
        
        // save current route
        const currentRoute = '/default/dashboard';
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
          this.router.navigate([currentRoute]);
        }); 
      });
    }
  }
}