import { Overlay } from "@angular/cdk/overlay";
import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { User } from "../user.model";
import { UserService } from "../user.service";
import { ToastrService } from 'ngx-toastr';
import { SimpleModalService } from 'ngx-simple-modal';
import { ConfirmComponent } from '../../views/confirm/confirm.component';

@Component({
  selector: "app-user-list",
  templateUrl: "./user-list.component.html",
  styleUrls: ["./user-list.component.scss"],
})
export class UserListComponent implements OnInit {
  data: any = [];
  users: User;
  elementClicked = 'Click any of the list item below';

  _currentPage = 1;
  _totalItems = 0;
  _sortKey: string = 'createdAt';
  _sortDirection: string = 'desc';
  _serchText: string = '';
  _accountType: string = '';

  add: boolean = false;
  edit: boolean = false;
  view: boolean = false;
  delete: boolean = false;
  activeDeactive: boolean = false;

  @Output() public idFormChild = new EventEmitter();
  constructor(
    private simpleModalService: SimpleModalService,
    private toastr: ToastrService,
    private userService?: UserService,
    public overlay?: Overlay) {

      const permissionData = sessionStorage.getItem('permissionData');
      // if permissionData is 0 then it is admin otherwise it is staff role.

      if (permissionData && permissionData.toString() != '0') {
        const permissionDataJson = JSON.parse(permissionData);
        const modulesAndPermissions = permissionDataJson.modulesAndPermissions;
        let viewPermitArr = [];
        modulesAndPermissions.map((ele) => {
          if (ele.moduleId === 1) {
            ele.permissions.map((e) => {
              if (e.itemName === 'Add' && e.checked) this.add = true;
              if (e.itemName === 'Edit' && e.checked) this.edit = true;
              if (e.itemName === 'View' && e.checked) this.view = true;
              if (e.itemName === 'Delete' && e.checked) this.delete = true;
              if (e.itemName === 'Activate/De-Activate' && e.checked) this.activeDeactive = true;
            })
          }
        })
      } else {
        this.add = true;
        this.edit = true;
        this.view = true;
        this.delete = true;
        this.activeDeactive = true;
      }
    }

  public getUserData(): void {
    this.userService.getUserList((this._currentPage), this._serchText, this._accountType, this._sortKey, this._sortDirection).subscribe((data) => {
      this.data = data.data.users;
      this._totalItems = data.data.total;
    }, err => {
      // this.toastr.error(err.error.message);
    });
  }

  deleteUser(id: string) {
    this.simpleModalService.addModal(ConfirmComponent, {
      title: 'Please Confirm',
      message: 'Are sure you want to delete this user?'
    }).subscribe((isConfirmed) => {
      if (isConfirmed) {
        this.userService.deleteUserById(id).subscribe(() => {
          this.toastr.success('User has been deleted.');
          this.getUserData();
        }, err => {
          this.toastr.error(err.error.message);
        });
      }
    });
  }

  ngOnInit(): void {
    if (this.userService.subsVar == undefined) {
      this.userService.subsVar = this.userService.
        invokeGetUserData.subscribe((name: string) => {

          this.getUserData();
        });
    }

    this.getUserData();
  }

  /** assign overlay */
  public openForm(data: any, isview: boolean) {
    this.userService.overlayConfig(data, isview);
  }

  changeuserStatus(id, status) {
    const text = status !== 0 ? 'Activate' : 'Inactive';

    this.simpleModalService.addModal(ConfirmComponent, {
      title: 'Please Confirm',
      message: `Are sure you want to ${text} this user?`
    }).subscribe((isConfirmed) => {
      if (isConfirmed) {
        this.userService.updateUserStatus(id, status).subscribe(() => {
          this.getUserData();
          this.toastr.success('Status has been updated.');
        }, err => {
          this.toastr.error(err.error.message);
        });
      }
    });
  }




  adjustSort(key: string) {

    if (key == this._sortKey) {
      this._sortDirection = this._sortDirection == 'asc' ? 'desc' : 'asc';
    } else {
      this._sortDirection = 'asc';
      this._sortKey = key;
    }

    this.getUserData();

  }

  pageChanged(a) {
    console.log("🚀 ~ file: user-list.component.ts ~ line 112 ~ UserListComponent ~ pageChanged ~ a", a)
    this._currentPage = a;
    this.getUserData();
  }

  onChnageSearchBox(txt) {

    if (txt == '' || txt.length > 2) {
      this.getUserData();
    }
  }

  onClickSearch() {
    if (this._serchText != '' && this._serchText.length > 2) {
      this.getUserData();
    }
  }

}
