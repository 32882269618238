import { Component } from '@angular/core';

@Component({
  templateUrl: 'cards.component.html'
})
export class CardsComponent {

  constructor() { }

}
