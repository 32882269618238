import { Component } from '@angular/core';

@Component({
  templateUrl: 'tabs.component.html'
})
export class TabsComponent {

  constructor() { }

}
