import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { navItems } from '../../_nav';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../auth/auth.service';
import { SimpleModalService } from 'ngx-simple-modal';
import { ConfirmComponent } from '../../views/confirm/confirm.component';
import { Location } from '@angular/common';
import { isUndefined } from 'lodash';
import { DefaultService } from "../default-layout/default.service";


@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent {
  public sidebarMinimized = false;
  public navItems = navItems;
  public _dyear = (new Date()).getFullYear();
  loginUserName: any;
  loginUserPic: any;
  lastName: any;
  roles: any;
  userId: any;
  sessionId: any;

  constructor(private router: Router, private http: HttpClient, private authService: AuthService,private toastr: ToastrService,
    private simpleModalService: SimpleModalService, private location:Location, private defaultService: DefaultService
    ) {

  }
  ngOnInit() {
    this.sessionId = sessionStorage.getItem('id');
    this.setProfile(this.sessionId);
    if (sessionStorage.getItem("isLogin") === null) {
      this.router.navigateByUrl('/login');
    }
    const state = history.state;
    if (!isUndefined(state.page) && state.page === 'login'){
      window.location.reload();
    }

    this.loginUserName = sessionStorage.getItem('loginUserName');
    this.loginUserPic = sessionStorage.getItem('loginUserPic');
    this.lastName = sessionStorage.getItem('loginUserName');
    this.userId = sessionStorage.getItem('id');
    // this.roles = sessionStorage.getItem('loginUserName');

  }

  setProfile(id){
    this.defaultService.getAdminUserbyId(id).subscribe((data) => {
      console.log("sessionDataId===>", data);
      this.loginUserPic = data.userDetails.profile_pic;
    });
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  userImageClick() {
    var data = {
      "id": this.userId,
      "first_name": this.loginUserName,
      "last_name": this.lastName,
      "profile_pic": this.loginUserPic
    };
    console.log("function called", data); 
    this.defaultService.overlayConfig(data, false);
 }

 //  "roles": this.roles


  Logout() {
    this.simpleModalService.addModal(ConfirmComponent, {
      title: 'Please Confirm',
      message: 'Are sure you want to Logout?'
    }).subscribe((isConfirmed) => {
      if (isConfirmed) {
        this.authService.ActiveUserLogout().subscribe(
          data => {
            sessionStorage.clear();
            this.router.navigateByUrl('login');
        }, err => {
          this.toastr.error(err.error.message);
        });
      }
    });

  }

  startedClass = false;
  completedClass = false;
  preventAbuse = false;

  onStarted() {

  }

  onCompleted() {

  }

  options = {
    minimum: 0.08,
    maximum: 1,
    ease: 'linear',
    speed: 200,
    trickleSpeed: 300,
    meteor: true,
    spinner: false,
    direction: 'leftToRightIncreased',
    color: 'red',
    thick: true
  };


  testHttp() {
    this.preventAbuse = true;
    this.http.get('https://reqres.in/api/users?delay=1').subscribe(res => {

    });
  }
}
