import { ComponentRef, EventEmitter, Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { Subscription } from 'rxjs/internal/Subscription';
import { User } from "./user.model";
import { Overlay, OverlayConfig, OverlayRef } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import { UserAddComponent } from "./user-add/user-add.component";
import { UserOrderUpdateComponent } from "./user-order-update/user-order-update.component";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class UserService {
  /** component reference */
  public componentRef: ComponentRef<UserAddComponent>;

  /* component reference */
  public orderUpdateRef: ComponentRef<UserOrderUpdateComponent>;

  /** overlay reference */
  public overlayRef: OverlayRef;

  /** overlay reference */
  public overlayUpdateRef: OverlayRef;

  public url: string = `${environment.baseUrl}admin`;
  public userurl: string = `${environment.baseUrl}driver`;

  public authToken = sessionStorage.getItem("auth_token");
  public httpOptions = {
    headers: new HttpHeaders({
      Authorization: this.authToken,
      'user_type': '1'
    }),
  };

  constructor(private http: HttpClient, public overlay?: Overlay) { }

  createUser(user): Observable<any> {
    return this.http.post(this.url, user);
  }

  public getUser(): Observable<any> {
    return this.http.get(this.url);
  }

  public getUserById(id): Observable<any> {
    return this.http.get(
      this.url + "/get-user-details/" + id,
      this.httpOptions
    );
  }

  public getUserOrderById(id): Observable<any> {
    return this.http.get(
      this.url + "/order/get-order-details/" + id,
      this.httpOptions
    );
  }

  public getUserRatingById(id): Observable<any> {
    return this.http.get(
      this.userurl + "/get-CustomerDetails/" + id,
      this.httpOptions
    );
  }

  putUser(id: number, data: User) {
    console.log("🚀 ~ file: user.service.ts ~ line 49 ~ UserService ~ putUser ~ data", data)
    const httpOptions ={
      headers: new HttpHeaders({
        Authorization: this.authToken,
        'user_type': '1',
      }),
    };
    return this.http.post(this.url + "/update-user/" + id, data, httpOptions);
  }

  updateOrderStatus(orderId, status) {
    const httpOptions ={
      headers: new HttpHeaders({
        Authorization: this.authToken,
        'user_type': '1',
      }),
    };
    var data = {
      "orderStatus": status
    };
    return this.http.post(`${this.url}/order/update-order-status/${orderId}`,data, this.httpOptions);
  }

  deleteUser(id: string): Observable<any> {
    return this.http.delete(this.url + "/" + id);
  }
  
  deleteUserOrderById(id: string): Observable<any> {
    return this.http.delete(this.url + "/order/delete-order/" + id);
  }

  getUserList(pageNumber, searchquery, accountType, sortKey, sortDirection) {
    return this.http.get<any>(
      `${this.url}/get-user-list?page=${pageNumber}&limit=10&search_query=${searchquery}&sortKey=${sortKey}&sortDirection=${sortDirection}${accountType != '' ? '&sub_user_type=' + accountType : ''}`,
      this.httpOptions
    );
  }

  getUserOrderList(userId, pageNumber, sortKey, sortDirection, searchquery, applicationType = 'All') {
    const appType = applicationType === 'All' ? '' : `&status=${applicationType}`;
    console.log("🚀 ~ file: driver.service.ts ~ line 57 ~ DriverService ~ getDriverOrderList ~ applicationType", applicationType)
    return this.http.get<any>(`${this.url}/get-user-order-list?userId=${userId}&page=${pageNumber}&limit=10&search_query=${searchquery}${appType}&sortKey=${sortKey}&sortDirection=${sortDirection}`, this.httpOptions);
  }
  deleteUserById(id) {
    return this.http.delete(`${this.url}/delete-user/${id}`, this.httpOptions);
  }

  updateUserStatus(id, status) {
    var data = {
      "user_id": id,
      "is_active": status
    };
    return this.http.put(this.url + "/active-deactive-user", data, this.httpOptions);


  }

  updateUserApprovStatus(id, status) {
    var data = {
      "user_id": id,
      "status": status
    };
    return this.http.put(this.url + "/user/approve-reject", data, this.httpOptions);


  }

  /** overlay configuration */
  public overlayConfig(data: any, isview): ComponentRef<UserAddComponent> {

    console.log("metg=od 2", data);
    const overlayConfig: OverlayConfig = new OverlayConfig();

    /* GlobalPostionStrategy */
    overlayConfig.positionStrategy = this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically();

    overlayConfig.hasBackdrop = true;

    this.overlayRef = this.overlay.create(overlayConfig);
    const portal: ComponentPortal<UserAddComponent> =
      new ComponentPortal<UserAddComponent>(UserAddComponent);
    this.componentRef = this.overlayRef.attach(portal);
    this.componentRef.instance.userId = data._id;
    this.componentRef.instance.isViewOnly = isview;


    this.overlayRef.backdropClick().subscribe(() => this.overlayRef.detach());

    return this.componentRef;
  }

   /** overlay configuration */
   public orderUpdateConfig(data: any, isview): ComponentRef<UserOrderUpdateComponent> {

    console.log("metg=od 2", data);    
    const overlayConfig: OverlayConfig = new OverlayConfig();

    /* GlobalPostionStrategy */
    overlayConfig.positionStrategy = this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically();

    overlayConfig.hasBackdrop = true;

    this.overlayUpdateRef = this.overlay.create(overlayConfig);
    const portal: ComponentPortal<UserOrderUpdateComponent> =
      new ComponentPortal<UserOrderUpdateComponent>(UserOrderUpdateComponent);
    this.orderUpdateRef = this.overlayUpdateRef.attach(portal);
    this.orderUpdateRef.instance.userId = data.id;
    this.orderUpdateRef.instance.isViewOnly = isview;
    this.orderUpdateRef.instance.isViewOnly = isview;
    this.overlayUpdateRef.backdropClick().subscribe(() => this.overlayUpdateRef.detach());
    return this.orderUpdateRef;
  }
  
  /** close overlay */
  public closeOverlay() {
    this.overlayRef.detach();

    this.componentRef.destroy();
  }


  invokeGetUserData = new EventEmitter();
  subsVar: Subscription;
  onCompleted() {
    this.invokeGetUserData.emit();
  }

    /** close overlay */
    public closeOrderUpdateOverlay() {
      this.overlayUpdateRef.detach();
  
      this.orderUpdateRef.destroy();
    }
}
